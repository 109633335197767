.landing-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* gap: 20px; */
  font-family: 'Lexend', sans-serif;
  position: relative;
}

.basic-wrapper {
  padding-left: 200px;
  padding-right: 200px;
  /* height: 400px;
  min-width: 100vw; */
  background-color: #fff;
}

.carousel-navigation {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: space-between;
  width: 95%;
  /* margin-top: 20px; */
  z-index: 99999;
}

.slide {
  width: 100%;
  /* transition: transform 0.5s ease-in-out; */
}

.slide-content > * {
  flex: 1;
  /*Take up 100% width */
  /*   width: 200vw; Ensure full width */
  display: hidden;
  /* Stack vertically */
  overflow: hidden;
  /* Ensure they don't overflow horizontally */
}

/* .slide-left {
  transform: translateX(-100%);
}

.slide-right {
  transform: translateX(100%);
} */

.carousel {
  position: relative;
  width: 100%;
  height: 400px;
  margin: 0 auto;
}

.carousel-container {
  position: relative;
  /* width: 100%;
  height: 400px; */
  z-index: 1;
}

.slide > * {
  width: 100%;
}

.circle-button {
  background-color: white;
  /* border: 2px solid black; */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  font-size: 24px;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  outline: none;
  opacity: 0.7;
}

.circle-button:hover {
  background-color: black;
  color: white;
  opacity: 1;
}

.carousel-dots {
  display: block;
  width: 100%;
  padding: 0;
  position: absolute;
  margin-top: 1em;
  list-style: none;
  text-align: center;
  bottom: 40px;
  z-index: 1;
}

.carousel-dot-1 > button,
.carousel-dot-2 > button,
.carousel-dot-3 > button,
.carousel-dot-4 > button {
  height: 10px;
  width: 10px;
  border-radius: 99999px;
  background: #fff;
}

.carousel-dot-1.carousel-dots-active > button,
.carousel-dot-2.carousel-dots-active > button,
.carousel-dot-3.carousel-dots-active > button,
.carousel-dot-4.carousel-dots-active > button {
  background: #e4e4e4;
}

.carousel-next,
.carousel-prev {
  height: 100%;
  display: flex;
  margin-left: 40px;
  margin-right: 40px;
  justify-content: center;
  align-items: center;
  opacity: 1 !important;
}

.hero-map {
  width: 100vw;
  height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.hero-location {
  width: 100%;
  height: 65px;
  line-height: 65px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-size: 25px;
  font-weight: 400;
  color: #000;
  text-transform: uppercase;
  z-index: 10;

  border-bottom: 2px solid #000;
  border-top: 2px solid #000;
}

.hero-location span {
  font-weight: 600;
}

.half-circle {
  width: 104px;
  height: 77px;
  background-color: #202020;
  border-radius: 0 0 52px 52px;
  position: relative;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 20px;
  top: -20px;
  z-index: 0;
}

.half-circle-up {
  width: 104px;
  height: 77px;
  background-color: #202020;
  border-radius: 52px 52px 0 0;
  position: absolute;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  bottom: 0;
  cursor: pointer;
  font-size: 20px;
  bottom: -15px;
  z-index: 0;
}

/* .half-circle::before {
    content: "MAP";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    color: #fff;
    border-radius: 0 0 50px 50px;
    transform: scaleX(0.5);
} */

.hero-search {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.hero-search h1 {
  font-size: 72px;
  font-weight: 400;
  color: #fff;
}

.hero-search-bar {
  background-color: #fff;
  border-radius: 25px;
  min-width: 525px;
  height: 40px;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* gap: 10px; */
  font-size: 15px;
  font-weight: 300;
  font-family: 'Lexend', sans-serif;
}

.hero-search-bar input {
  flex: 1;
  border: none;
  font-size: 15px;
  font-weight: 300;
  padding: 5px;
}

.hero-search-bar img {
  cursor: pointer;
}

.hero-search-bar-icon {
  font-size: 24px;
  font-weight: 400;
  color: rgba(32, 32, 32, 0.74);
}

.hero-search-bar-input-new {
  font-size: 15px;
  font-weight: 300;
  font-family: 'Lexend', sans-serif;
}

.scroll-recommend-left {
  color: #727272;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 50px;
  position: absolute;
  z-index: 99;
  cursor: pointer;
  position: absolute;
  left: 0;
}

.scroll-recommend-right {
  color: #727272;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 50px;
  position: absolute;
  z-index: 99;
  cursor: pointer;
  position: absolute;
  right: 0;
}

.scroll-left {
  background-color: rgba(32, 32, 32, 0.5);
  color: #fff;
  width: 40px;
  height: 40px;
  border-radius: 44px;
  display: none;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  position: absolute;
  left: 0;
  z-index: 99;
  cursor: pointer;
}

.scroll-left2 {
  float: left;
  /* position: absolute; */
  background-color: rgba(32, 32, 32, 0.5);
  color: #fff;
  width: 40px;
  height: 40px;
  border-radius: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  left: 0;
  z-index: 99;
  cursor: pointer;
}

.scroll-right {
  background-color: rgba(32, 32, 32, 0.5);
  color: #fff;
  width: 40px;
  height: 40px;
  border-radius: 44px;
  display: none;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  position: absolute;
  right: 0;
  z-index: 99;
  cursor: pointer;
}

.scroll-wrap:hover > .scroll-left {
  background-color: rgba(32, 32, 32, 0.7);
  display: flex;
}

.scroll-wrap:hover > .scroll-right {
  background-color: rgba(32, 32, 32, 0.7);
  display: flex;
}

.carousel-common-wrapper {
  width: 100%;
  height: 500px;
}

.carousel-common-wrapper-new {
  width: 824px;
  /* height: 580px; */
  /* border-radius: 0 30px 30px 30px; */
}

.carousel-common-wrapper-new img {
  width: 824px;
  height: 580px;
  /* object-fit: contain; */
  /* border-radius: 30px; */
  border-radius: 0 30px 30px 30px;
}

@media screen and (max-width: 1400px) {
  .carousel-common-wrapper-new {
    width: 715px;
  }

  .carousel-common-wrapper-new img {
    width: 715px;
    height: 500px;
  }
}

@media screen and (max-width: 960px) {
  .scroll-left {
    display: none;
  }

  .scroll-right {
    display: none;
  }

  .hero-location {
    font-size: 1rem;
    font-weight: 400;
  }

  .hero-search h1 {
    font-size: 1.5rem;
  }

  .hero-search-bar {
    width: 100%;
    min-width: 100%;
  }

  .scroll-left {
    left: 10px;
  }

  .scroll-right {
    right: 10px;
  }

  .scroll-recommend-left {
    left: 10px;
  }

  .scroll-recommend-right {
    right: 10px;
  }

  .carousel-common-wrapper {
    height: 250px;
  }

  .carousel-common-wrapper-new {
    /* width: 100%; */
    height: 300px;
    border-radius: 0;
  }

  .carousel-common-wrapper-new img {
    width: 100%;
    /* height: 300px; */
    border-radius: 0;
    object-fit: contain
  }
}
